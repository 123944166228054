import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
import VueTheMask from 'vue-the-mask'
import vueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLinkedin, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueTheMask)
Vue.use(VueRouter)
Vue.use(vueMeta)
Vue.use(VueGtag, {
  config: { id: "G-0F7QMX4R2L" }
})

library.add(faLinkedin, faFacebookSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.prototype.$http = axios;

function lazyLoading(view){
  return () => import(`@/components/${view}.vue`)
}

const routes = [
  { path: '/', component: lazyLoading('Home') },
  { path: '/conheca-empresa', component: lazyLoading('Empresa') },
  { path: '/localizacao', component: lazyLoading('Localizacao') },
  { path: '/produtos/detalhes/:produto', component: lazyLoading('ProdutosDetalhe') },
  { path: '/produtos', component: lazyLoading('Produtos') },
  { path: '/produtos/:campo/:valor', component: lazyLoading('Produtos') },
  { path: '/fale-conosco', component: lazyLoading('FaleConosco')},
  { path: '/trabalhe-conosco', component: lazyLoading('TrabalheConosco') },
  { path: '/administracao', component: lazyLoading('Administracao')}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
